<!-- eslint-disable vue/camelcase -->
<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="MARKS_DETAILS"
      tooltip-title="MARKS_DETAILS"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="subjectDetails" custom-classes="flex-col lg:w-1/2" />
      <div>
        <Heading heading="Students" heading-size="pb-0 text-xl" />
        <div v-if="isEmpty(resultList)">
          <NoRecordFound class="mt-28" />
        </div>
        <TableWrapper v-else>
          <THead>
            <TRHead>
              <TH v-for="(head, index) in tableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(result, index) in resultList" :key="index">
              <TD>
                <SingleUserDisplay
                  :user="result.student"
                  :is-object-concated="false"
                  label="full_name"
                  :image="true"
                />
              </TD>
              <TD>
                {{ result.obtained_marks }}
              </TD>

              <TD>
                {{ result.private_comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <TableAction
                  :idx="index"
                  :current-user="result"
                  :action-list="actionList"
                  @action="typeAction"
                />
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
    </div>
    <Pagination
      v-if="resultCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
      v-show="!isLoading"
      :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
      :max-range="paginationCounts(resultCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
      @filterRecord="filteredRecords"
    />
    <UpdateResultModal
      v-if="isModalView"
      :modal="isModalView"
      :result="currentResult"
      @toggle="toggleUpdateResultModal"
    />

    <UIConfirmationModal
      v-if="deleteModelShow"
      :modal="deleteModelShow"
      heading="Delete Marks of Student"
      message="Are you sure you want to delete marks of "
      button-text="Delete"
      :name="currentResult.student.full_name"
      @cancel="toogleDeleteModel"
      @confirm="deleteStudentMarksById"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import DATE_TIME_CONSTANT from '@src/constants/date-time-constants.js'
import scrollMixin from '@src/mixins/scroll-mixin'
import Heading from '@components/Heading.vue'
import UpdateResultModal from '@src/router/views/exam-planner/exam-subject-result/EditStudentExamSubjectResultModal.vue'
import { toPascalCase } from '@utils/generalUtil'
import TableAction from '@components/TableAction.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import {
  paginationRangeHandler,
  paginationCounts,
} from '@src/components/BaseComponent/pagination.util.js'
import { fullName } from '@/src/utils/settings/tenant-user.util'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import isEmpty from 'lodash/isEmpty'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
import DetailCard from '@/src/components/UiElements/DetailCard.vue'
export default {
  components: {
    TitleWrapper,
    Loader,
    Heading,
    UpdateResultModal,
    TableAction,
    NoRecordFound,
    SingleUserDisplay,
    UIConfirmationModal,
    Pagination,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
    DetailCard,
  },

  mixins: [scrollMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      DATE_TIME_CONSTANT,
      tableHeaders: ['Student Name', 'Obtained Marks', 'Comment', 'Actions'],
      isLoading: false,
      isModalView: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      subjectDetails: {},
      currentSelectedSubject: null,
      resultCounts: 0,
      resultList: [],
      deleteModelShow: false,
    }
  },
  page: {
    title: 'Exam Subject Result',
  },
  computed: {
    ...mapState('layout', ['currentClassScope', 'currentSectionScope']),
    currentExamId() {
      return this.$route.params.id
    },
    currentSubjectId() {
      return this.$route.params.subjectId
    },
  },
  watch: {
    currentClassScope: {
      handler() {
        this.$router.push({ name: 'exams' })
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.$router.push({ name: 'exams' })
      },
      deep: true,
    },
  },
  mounted() {
    this.filteredRecords()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    paginationCounts,
    toPascalCase,
    toggleUpdateResultModal(payload) {
      this.isModalView = !this.isModalView
      if (!this.isModalView) this.currentResult = null
      if (payload) this.filteredRecords()
    },

    deleteStudentMarksById() {
      this.deleteMarks(this.currentResult.id)
    },
    async deleteMarks(id) {
      await this.deleteSingleResultOfSubjectExam(id)
      this.filteredRecords()
      this.toogleDeleteModel()
    },
    async filteredRecords(range) {
      this.isLoading = true

      let filterQueries = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.EQUAL, 'subject_exam_schedule_id', this.currentSubjectId),
        },
      }

      let payload = {
        ...paginationRangeHandler(range),
        ...filterQueries,
      }
      let [response, error] = await this.getSubjectExamResult(payload)
      this.subjectDetails = this.getDetails(response.data.data.subject_exam_schedule)
      this.resultList = response.data.data.records
      this.resultList.forEach((result) => fullName(result.student))
      this.resultCounts = response.data.meta.total_records
      this.isLoading = false
      this.setRightbarData()
    },
    getDetails(details) {
      return {
        exam: details.exam_schedule.title,
        subject: details.subject.title,
        status: details.status,
        totalMarks: details.total_marks,
      }
    },
    typeAction(action, currentType, idx) {
      this.currentResult = currentType
      switch (action) {
        case 'Edit':
          this.toggleUpdateResultModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
      }
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentResult = null
    },
    setRightbarData() {
      const rightBarContent = {
        header: {
          title: 'SUBJECT_RESULT_DETAILS',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'Total_RESULT', value: this.resultCounts }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['getSubjectExamResult', 'deleteSingleResultOfSubjectExam']),
  },
}
</script>
