<template>
  <ValidationObserver @submit="updateStudentResult">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      :disable-position-center="true"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Edit Result for {{ resultDetails.student.full_name }}</span>
      </template>
      <template v-slot>
        <div class="py-6 px-6">
          <div class="flex flex-col">
            <div class="">
              <InputBox
                v-model="resultDetails['obtained_marks']"
                type="text"
                name="MARKS"
                title="MARKS"
                label="MARKS"
                placeholder="MARKS"
                :class="$style.widthItems"
                class="flex-1 w-full"
                rules="required"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Update</span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import InputBox from '@components/UiElements/UiInputBox.vue'
import { Form as ValidationObserver } from 'vee-validate'
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    UiModalContainer,
    InputBox,
    ValidationObserver,
    Loader,
    UIButton,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    result: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      isLoading: false,
      resultDetails: {},
    }
  },
  created() {
    this.resultDetails = objectDeepCopy(this.result)
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    async updateStudentResult() {
      this.isLoading = true
      let payload = {
        data: { obtained_marks: this.resultDetails.obtained_marks },
        id: this.resultDetails.id,
      }
      let [res, err] = await this.updateSingleResultOfSubjectExam(payload)
      if (err) this.isLoading = false
      else this.$emit('toggle', true)
    },

    ...mapActions('exams', ['updateSingleResultOfSubjectExam']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
